import ContactForm from './modules/ContactForm'
import Menu from './modules/Menu'

// Debug helper
const debug = true

if (!debug) {
  console.log = function () {}
}

export default class Main {
  constructor() {
    this.init()
  }

  init() {
    // run once when page loads
    window.addEventListener('load', () => {
      this.initialize()
    })
  }

  initialize() {
    ContactForm.init()
    Menu.init()
  }

  destory() {}
}

const main = new Main()
