export default class Menu{
    constructor(menu, index){
        this.menu = menu
        this.menuHeight = this.menu.offsetHeight
        this.index = index
        this.prevScroll = window.scrollY
        this.showHide = this.showHide.bind(this)

        this.init()
    }

    init(){
        this.addListeners();
    }

    addListeners(){
        window.addEventListener('scroll', this.showHide)
    }

    showHide(){
        var currentScroll = window.scrollY
        if(currentScroll > this.prevScroll && currentScroll > this.menuHeight){
            this.menu.style.top = - this.menuHeight + 'px'
        }if(currentScroll <= this.prevScroll){
            this.menu.style.top = 0
        }
        this.prevScroll = currentScroll
    }

    static init(){
        Menu.items = Array.from(
            document.querySelectorAll('header')
          ).map((menu, index) => new Menu(menu, index))
    }
}