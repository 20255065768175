import JustValidate from 'just-validate'

export default class ContactForm {
  static init() {
    const form = document.querySelector('#contact-form')
    const formSubmittButton = form.querySelector('.button')

    let postBody = {}

    // init validation
    const validation = new JustValidate('#contact-form')

    validation
      .addField('#lastname', [
        {
          rule: 'required',
          errorMessage: 'Il cognome è obbligatorio',
        },
      ])
      .addField('#email', [
        {
          rule: 'required',
          errorMessage: "L'email è obbligatoria",
        },
        {
          rule: 'email',
          errorMessage: "L'email non è valida",
        },
      ])
      .onSuccess((event) => {
        formSubmittButton.classList.add('h-visually-disabled')

        postBody = {
          lastname: form.elements.lastname.value,
          name: form.elements.name.value,
          agency: form.elements.agency.value,
          hectares: form.elements.hectares.value,
          sites: form.elements.sites.value,
          email: form.elements.email.value,
          message: form.elements.message.value,
        }

        const formSubmissionMsgOK = document.querySelector(
          '.form-submission-message.success'
        )
        const formSubmissionMsgKO = document.querySelector(
          '.form-submission-message.error'
        )

        const asyncPostCall = async () => {
          formSubmissionMsgOK.classList.remove('show')
          formSubmissionMsgKO.classList.remove('show')

          try {
            console.log('Try: fetch ./sendMail.php')

            const response = await fetch('./sendMail.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(postBody),
            })

            const result = await response.text()

            console.log('result: ', result)

            if (result === 'OK') {
              formSubmissionMsgOK.classList.add('show')
            } else {
              formSubmissionMsgKO.classList.add('show')
            }

            formSubmittButton.classList.remove('h-visually-disabled')
          } catch (error) {
            formSubmissionMsgKO.classList.add('show')
            formSubmittButton.classList.remove('h-visually-disabled')
            console.log(error)
          }
        }

        asyncPostCall()
      })
  }
}
